.typewriter {
  font-size: 4vw;
  height: 6rem;
}

@media (max-width: 800px) {
  .typewriter {
    font-size: 1.5rem;
  }
}
