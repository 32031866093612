.stackWrapper {
  width: 90%;
}

.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 4rem);
  gap: 3rem 1vw;
  align-items: center;
  justify-items: center;
}

.stackItem {
  color: rgba(255, 255, 255, 0.8);
  font-size: 6vw;
  position: relative;
  transition: all 300ms ease-in-out;

  &:hover {
    color: skyblue;
    transform: scale(1.2);
  }
}

.middle {
  color: black;
}

.item {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1px;
    height: 1px;
    transform: translate(-50%, -50%);
    transition: all ease-in-out 300ms;
  }

  &:hover::before {
    box-shadow: 0 0 200px 15px white;
  }

  &::after {
    content: attr(data-info);
    position: absolute;
    left: 50%;
    top: 110%;
    transform: translate(-50%, 0) scale(0);
    transition: transform 300ms ease-in-out;
  }
  &:hover::after {
    transform: translate(-50%, 0);
  }
}

.item0 {
  grid-column: 1/1;
}

.item1 {
  grid-column: 3/3;
}

.item2 {
  grid-column: 5/5;
}

.item3 {
  grid-column: 2/2;
}

.item4 {
  grid-column: 4/4;
}

.item5 {
  grid-column: 1/1;
}

.item6 {
  grid-column: 3/3;
}

.item7 {
  grid-column: 5/5;
}

@media (max-width: 800px) {
  .container {
    gap: 1rem;
    padding: 0;
  }
  .stackItem {
    font-size: 10vw;
  }

  .stackWrapper {
    padding-bottom: 3rem;
  }
}
