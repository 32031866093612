.experience-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px darkgray solid;
  border-radius: 10px;
  padding: 20px;
  gap: 20px;
  background-color: rgba(255,255,255,0.05);
  
  &:hover {
    background-color: rgba(255,255,255,0);
  }

  .experience-card-header {
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .experience-name {
      font-size: 1.3rem;
      font-weight: 700;
    }

    .experience-position {
      font-size: 1.7rem;
      font-weight: 400;
    }

    .experience-period {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .experience-card-body {
    font-size: 1rem;
    display: flex;
    width: 100%;
    justify-content: start;
  }
}