.experience-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 80%;

  h1 {
    font-size: 3rem;
  }
}

.experience-list {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 80%;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .experience-list {
    width: 100%;
  }
}


