.portfolioItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  opacity: 0.3;
  padding-bottom: 5rem;
  perspective: 200px;
  transition: all ease-in-out 250ms;

  h2 {
    font-size: 2rem;
  }
}

.isVisible {
  opacity: 1;
}

.actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  font-size: 1.3rem;
  align-items: center;

  .actionBtn {
    border: white 1px solid;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: ease-in-out 50ms all;

    span {
      margin-right: 10px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transform: scale(1.1);
    }
  }
}

.portfolioItemContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;

  .singleImg {
    width: 80%;
    object-fit: cover;
    border-radius: 10px;
    transition: box-shadow 300ms ease-in-out;

    &:hover {
      box-shadow: 10px 10px 10px black;
    }
  }
}

.mobileImgs {
  display: flex;
  gap: 30px;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  .mobileImg {
    width: 40%;
    border: gray 1px solid;
    object-fit:contain;
    border-radius: 10px;
    transition: box-shadow 300ms ease-in-out;

    &:hover {
      box-shadow: 10px 10px 10px black;
    }
  }
}

.leftContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.portfolioItemStack {
  display: flex;
  gap: 1rem;
}

a {
  font-size: 1.5rem;
  text-decoration: none;
  color: cyan;

  &:hover {
    color: lightblue;
  }
}

@media (max-width: 800px) {
  .portfolioItemWrapper {
    width: 100%;
    margin: 0;
  }

  .actions {
    justify-content: center;
  }

  .portfolioItemContainer {
    display: flex;
    flex-direction: column;
    width: 95%;

    img {
      width: 100%;
    }

    .leftContainer {
      text-align: center;
      align-items: center;
      gap: 0;
    }
  }
}
