.footer {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 0.8rem;
  padding: 0.3rem 0;
  background-color: rgba(0, 0, 0, 1);
}


.footer a {
  font-size: 0.8rem;
  padding: 0 5px
}