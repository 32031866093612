.navbar {
  width: 100%;
  font-size: 1.5rem;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0;

  max-height: 2rem;
  top: 0;
  left: 0;

  .navItem {
    color: white;
    background-color: rgba(100, 100, 100, 0.2);
    padding: 0 1rem 0.3rem;
    margin-inline-end: 1rem;
    border-radius: 0 0 0.5em 0.5em;
    align-self: flex-start;
    transition: padding 300ms ease-in-out, background 300ms ease-in-out;
    cursor: pointer;

    &:hover {
      padding: 0.4rem 1rem 0.5rem;
      background-color: rgba(100, 100, 100, 0.4);
    }
  }
}

.navLogo {
  $size: 3rem;

  position: absolute;
  top: $size * 1.3;
  left: $size * 1.1;
  transform: translate(-50%, -50%);

  img {
    width: $size;
    opacity: 0.5;
    cursor: pointer;
    filter: brightness(3);
    transition: width 300ms cubic-bezier(0, 2, 1, 0.53), opacity 300ms linear;

    &:hover {
      width: $size * 1.2;
      opacity: 1;
    }
  }
}

@media (max-width: 600px) {
  .navbar {
    justify-content: center;
  }

  .navLogo {
    $size: 2rem;

    top: 1.1 * $size;
    left: $size;

    img {
      width: $size;
    }
  }
}
