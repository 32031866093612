body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.95)),
    url("./assets/img/backgroundHexagons.jpg");
  perspective: 10px;
  scroll-behavior: smooth;
  animation: breathing 1000ms alternate infinite;
}

.App {
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 200px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;

  &::after {
    opacity: 0.05;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
  }
}
