.portfolio-section {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolioWrapper {
  width: 80%;
}

.portfolioItem {
  margin-bottom: 2rem;
}

.isVisible {
  transform: translateX(0);
  transition: all ease-in-out 250ms;
}